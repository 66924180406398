import React, { useState } from 'react';
import styled from 'styled-components';
import { VscClose } from '@react-icons/all-files/vsc/VscClose';
import marker from '../assets/images/marker.svg';
import distributor from '../assets/images/distributor.svg';

const MarkerStyled = styled.div`
  .dealer-card {
    display: none;
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    position: absolute;
    bottom: 40px;
    left: -100px;
    background: var(--backgroundProduct);
    border: 1px solid var(--border);
    box-shadow: var(--boxShadow);
    padding: 0.5rem 1rem 1rem;
    border-radius: 1rem;
    z-index: 2;
    font-family: PitchSansSemi;
    p {
      margin: 1rem 0;
    }
    strong {
      font-weight: bold;
      font-size: 1.25rem;
      text-transform: uppercase;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
    }
    ul li {
      display: inline-block;
      margin-right: 1rem;
    }
    a {
      color: var(--borderColor);
      font-size: 14px;
    }
  }
  .dealer-card.active {
    display: block;
  }
  .dealer-icon.active {
    filter: brightness(1.25);
    z-index: 1;
  }
  img {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
    scale: 1;
    transition: 0.5s;
  }
  svg.close-card {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 2rem;
    cursor: pointer;
  }
`;

function MapMarker(props) {
  const [cardState, cardToggle] = useState(false);

  function toggleCard() {
    const dealerCards = document.querySelectorAll('.dealer-card.active');
    dealerCards.forEach((card) => card.classList.remove('active'));
    cardToggle(!cardState);
  }

  function dealerBorder(e) {
    const dealer = document.querySelector(
      `[data-dealer-id="${e.currentTarget.dataset.dealerMarkerId}"]`
    );
    dealer.style.borderColor = 'var(--borderColor)';
  }

  function dealerBorderRemove(e) {
    const dealer = document.querySelector(
      `[data-dealer-id="${e.currentTarget.dataset.dealerMarkerId}"]`
    );
    dealer.style.borderColor = 'var(--backgroundProduct)';
  }

  return (
    <MarkerStyled>
      <div
        className={cardState ? 'dealer-card active' : 'dealer-card'}
        key={props.details.id}
      >
        <VscClose className="close-card" onClick={toggleCard} />
        <p>
          <strong>{props.details.name}</strong>
        </p>
        <ul>
          {props.details.website && (
            <li>
              <a
                href={props.details.website}
                title={props.details.name}
                target="_blank"
                rel="noreferrer"
              >
                Website
              </a>
            </li>
          )}
          {props.details.phone && (
            <li>
              <a
                href={`tel:${props.details.phone}`}
                title={props.details.name}
                target="_blank"
                rel="noreferrer"
              >
                {props.details.phone}
              </a>
            </li>
          )}
          {/* {props.details.email && (
            <li>
              <a
                href={`mailto:${props.details.email}`}
                title={props.details.name}
                target="_blank"
                rel="noreferrer"
              >
                Email
              </a>
            </li>
          )} */}
          {props.details.location.lat && (
            <li>
              <a
                href={
                  props.details.googleMap
                    ? props.details.googleMap
                    : `https://maps.google.com/?q=${props.details.location.lat},${props.details.location.lng}`
                }
                title={props.details.name}
                target="_blank"
                rel="noreferrer"
              >
                Location
              </a>
            </li>
          )}
        </ul>
      </div>
      <span onClick={toggleCard}>
        <img
          src={props.details.type == 'retailer' ? marker : distributor}
          className={cardState ? 'dealer-icon active' : 'dealer-icon'}
          data-dealer-marker-id={props.details.id}
          onMouseEnter={dealerBorder}
          onMouseLeave={dealerBorderRemove}
        />
      </span>
    </MarkerStyled>
  );
}

export default MapMarker;
